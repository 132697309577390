<template>
  <div class="education">
    <div
      class="container-fluid typeStyle type-fa_wlxljy"
      :style="{backgroundImage: 'url(' + require('@/assets/img/children/bg-fa_wlxljy.jpg') + ')'
      }"
    >
      <div class="container">
        <div class="row moreDes-titBox">
          <h1 class="Tit">学历教育</h1>
          <p class="moreDes">
            从事网络高等学历教育服务20余年，拥有高品质的技术平台及产品、规范的线上线下招生渠道。根据院校需求提供灵活多样的服务模式，保障合作院校快速启动、稳健发展。
          </p>
          <div class="clearfix">
            <a href="https://qzjy.net.cn/" class="to-more btnHover">学历咨询<i class="c-alink"></i></a>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid cont-nav">
      <div class="row cont-nav1">
        <div class="container">
          <div class="clearfix">
            <ul class="fl cont-navL">
              <li id="Anchor">
                <a class="cur">服务模式</a>
                <a class="">合作优势</a>
              </li>
            </ul>
            <div class="fr cont-navSel">
              <a >公共服务体系<i>&gt;</i></a>
              <div class="navSel">
                <a  class="vbn">学历教育<i class="Down">&gt;</i></a>
                <ul class="cont-navList">
                  <li class="cont-navCur"><a >学历教育</a></li>
                  <li><a >赑风学习中心</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row cont-nav2"></div>
    </div>
    <div class="clearfix TW-box" id="a1">
      <div class="container-fluid TW-Wrap bg-grey">
        <div class="container-fluid TitleWrap">
          <div class="title">
            <h2 class="T">服务模式</h2>
            <i></i>
          </div>
        </div>
      </div>
      <div class="TW-Wrap TW-Wrap-li2">
        <div class="container-fluid">
          <!-- 图文/列表 TW -->
          <div class="row TW-list TWicon-wlxl TW-Scon">
            <div class="container-fluid TW-item clearfix bg-white">
              <div class="container">
                <div class="xs12 xm6 xb6 TW-con">
                  <div class="TW-title">
                    <i></i>
                    <h2 class="T">全面合作服务</h2>
                  </div>
                  <div class="TW-info">
                    <p>
                      与院校共同制定发展路线，打造院校品牌，并充分挖掘院校品牌资源及教学资源优势，实现合作价值最大化。
                    </p>
                    <img
                      class="hidden-b show-s"
                      src="../../../../assets/img/children/fa_jxjy_pic01@2x.png"
                    />
                    <ul class="TW-info-list">
                      <li>
                        <i class="TW-icon"></i>
                        <div class="TW-pCon">
                          <h6>教学体制创新</h6>
                          <p>
                            覆盖业务模型设计、教学模式创新、资源分配方案等多方面
                          </p>
                        </div>
                      </li>
                      <li>
                        <i class="TW-icon"></i>
                        <div class="TW-pCon">
                          <h6>网教整体解决</h6>
                          <p>
                            基于新背景需求，提供全方位教育信息化产品与技术支持
                          </p>
                        </div>
                      </li>
                      <li>
                        <i class="TW-icon"></i>
                        <div class="TW-pCon">
                          <h6>运营咨询规划</h6>
                          <p>系统运营维护，业务咨询与管理、系统培训等</p>
                        </div>
                      </li>
                      <li>
                        <i class="TW-icon"></i>
                        <div class="TW-pCon">
                          <h6>人才团队搭建</h6>
                          <p>共同组建管理团队，构建完善的管理制度</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="xs12 xm6 xb6 clearfix TW-img">
                  <img
                    src="../../../../assets/img/children/fa_jxjy_pic01@2x.png"
                  />
                </div>
              </div>
            </div>
            <div class="container-fluid TW-item clearfix bg-grey-s">
              <div class="container">
                <div class="xs12 xm6 xb6 TW-con">
                  <div class="TW-title">
                    <i></i>
                    <h2 class="T">项目招生服务</h2>
                  </div>
                  <div class="TW-info">
                    <p>
                      规范化、标准化的线上、线下招生服务平台，优质的教育资源，强大的师资团队，多年的教学服务经验，全方位的教学支持，实现高质量教学服务目标。
                    </p>
                    <img
                      class="hidden-b show-s"
                      src="../../../../assets/img/children/fa_jxjy_pic01@2x.png"
                    />
                    <ul class="TW-info-list">
                      <li>
                        <i class="TW-icon"></i>
                        <div class="TW-pCon">
                          <h6>优质渠道</h6>
                          <p>
                            为院校提供规范化的线上线下招生服务平台，充分保证生源质量
                          </p>
                        </div>
                      </li>
                      <li>
                        <i class="TW-icon"></i>
                        <div class="TW-pCon">
                          <h6>全面服务</h6>
                          <p>
                            为学生提供学校推荐、专业选择、督学导学、职业规划等一站式服务
                          </p>
                        </div>
                      </li>
                      <li>
                        <i class="TW-icon"></i>
                        <div class="TW-pCon">
                          <h6>共享资源</h6>
                          <p>
                            最大化激发、共享双方的资源价值，实现校际共享与合作共赢
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="xs12 xm6 xb6 clearfix TW-img">
                  <img
                    src="../../../../assets/img/children/fa_jxjy_pic01@2x.png"
                  />
                </div>
              </div>
            </div>
            <div class="container-fluid TW-item clearfix bg-white">
              <div class="container">
                <div class="xs12 xm6 xb6 TW-con">
                  <div class="TW-title">
                    <i></i>
                    <h2 class="T">技术合作服务</h2>
                  </div>
                  <div class="TW-info">
                    <p>
                      为处于发展初级阶段的教育用户提供标准化软件包和技术产品，助力实现信息化基础需求；为处于发展成熟阶段的教育用户提供个性化技术解决方案以及全天候运维服务，保障用户全程无忧。
                    </p>
                    <img
                      class="hidden-b show-s"
                      src="../../../../assets/img/children/fa_jxjy_pic03@2x.png"
                    />
                    <ul class="TW-info-list">
                      <li>
                        <i class="TW-icon"></i>
                        <div class="TW-pCon">
                          <h6>基础需求</h6>
                          <p>
                            满足用户教育信息化起步阶段需求，保障软件包可用、可靠，帮助用户高效搭建平台快速启动业务。
                          </p>
                        </div>
                      </li>
                      <li>
                        <i class="TW-icon"></i>
                        <div class="TW-pCon">
                          <h6>个性需求</h6>
                          <p>
                            满足个性化定制需求，帮助解决所有技术领域需求，享受赑风系统安全、实施、数据挖掘等高价值服务。
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="xs12 xm6 xb6 clearfix TW-img">
                  <img
                    src="../../../../assets/img/children/fa_jxjy_pic03@2x.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="project information" style="background: #f0f2f5">
      <div class="title">
        <h2>合作优势</h2>
        <i></i>
      </div>
      <div class="container" style="padding-bottom:0">
        <div class="list">
          <div class="item">
            <a  class="qwe">
              <span class="imgSpan">
                <img
                  src="../../../../assets/img/children/jjfa3_wl_pic01@2x.jpg"
                  alt=""
                />
              </span>
              <div class="blo-con">
                <h2>模式多样</h2>
                <p class="time">
                  拥有灵活多样的服务模式，根据院校需求量身定制个性化服务
                </p>
              </div>
            </a>
          </div>
          <div class="item">
            <a  class="qwe">
              <span class="imgSpan">
                <img
                  src="../../../../assets/img/children/jjfa3_wl_pic02@2x.jpg"
                  alt=""
                />
              </span>
              <div class="blo-con">
                <h2>丰富经验</h2>
                <p class="time">
                  拥有20余年网络高等教育服务经验，能为网络教育学院清晰规划发展蓝图，保证院校稳健发展
                </p>
              </div>
            </a>
          </div>
          <div class="item">
            <a  class="qwe">
              <span class="imgSpan">
                <img
                  src="../../../../assets/img/children/jjfa3_wl_pic03@2x.jpg"
                  alt=""
                />
              </span>
              <div class="blo-con">
                <h2>高效管理</h2>
                <p class="time">
                  采用国际化管理机制，协助合作院校构建高效的管理平台
                </p>
              </div>
            </a>
          </div>
          <div class="item">
            <a  class="qwe">
              <span class="imgSpan">
                <img
                  src="../../../../assets/img/children/jjfa3_wl_pic04@2x.jpg"
                  alt=""
                />
              </span>
              <div class="blo-con">
                <h2>专业团队</h2>
                <p class="time">
                  拥有专业的技术开发团队，提供高品质的技术平台及教育产品
                </p>
              </div>
            </a>
          </div>
          <div class="item">
            <a  class="qwe">
              <span class="imgSpan">
                <img
                  src="../../../../assets/img/children/jjfa3_wl_pic05@2x.jpg"
                  alt=""
                />
              </span>
              <div class="blo-con">
                <h2>成熟运维</h2>
                <p class="time">
                  提供成熟的运维解决方案，高品质硬件设备，数百名专家团队7X24小时服务
                </p>
              </div>
            </a>
          </div>
          <div class="item">
            <a  class="qwe">
              <span class="imgSpan">
                <img
                  src="../../../../assets/img/children/jjfa3_wl_pic06@2x.jpg"
                  alt=""
                />
              </span>
              <div class="blo-con">
                <h2>优质渠道</h2>
                <p class="time">
                  拥有规范的线上、线下招生服务平台，为学生提供学校推荐、专业选择、督学导学一站式服务
                </p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods:{
  },

};
</script>

<style lang="scss" scoped>
.education{
  z-index: 444444444;
}
.information {
  margin: 0;
  padding: 60px 0;
  .item {
    .blo-con {
      padding: 35px 30px;
      h2 {
        font-size: 22px;
        text-align: center;
        margin: 0;
      }
      p {
        margin: 0;
        padding: 0;
        color: #666;
      }
    }
  }
}
</style>
<style lang="scss">
@import "../../../../assets/css/header/children/index.scss";
</style>
